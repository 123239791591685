<template>
  <div class="main_accelerate">
    <Accelerate />
    <AcclerateWays />
  </div>
</template>
<script>
import Accelerate from "@/views/Home/Accelerate";
import AcclerateWays from "@/views/AcclerateWays";
export default {
  name: "main_accelerate",
  components: {
    Accelerate,
    AcclerateWays
  }
};
</script>
<style lang="scss">
.main_accelerate{
    padding:50px 0;
}
</style>
