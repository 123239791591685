<template>
  <div class="accelerate">
    <h1>为什么需要回国加速？</h1>
    <p
      class="desc"
    >身处海外，观看电视剧、听音乐、看体育直播经常会遇到【地区限制，无法播放】和【音视频卡顿】。解锁和加速不限于优酷、哔哩哔哩 B站、爱奇艺、腾讯视频、QQ音乐、土豆、网易云音乐、虾米音乐、酷狗音乐、芒果直播 MGTV、Acfun、PPTV、章鱼TV、球彩直播、网易公开课等平台。</p>
<!--     <router-link to="/recharge"><button class="btn">一键加速回国</button></router-link>-->
    <br><br>
    <div class="ac_box">
      <div class="box">
        <img src="../../assets/home_ic_clouds.png" alt />
        <p>高速专线极速回国</p>
      </div>
      <div class="box">
        <img src="../../assets/home_ic_defense.png" alt />
        <p>用户隐私数据加密传输</p>
      </div>
      <div class="box">
        <img src="../../assets/home_ic_monitoring.png" alt />
        <p>解锁国内视频音乐限制</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "accelerate",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.accelerate {
  padding: 0 16px;
  h1 {
    font-size: 26px;
    color: #202020;
    margin: 30px 0;
    font-weight: 500;
    text-align: center;
  }
  .desc {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 28px;
  }
  .btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #517cfc;
    color: #517cfc;
    background: #fff;
    margin-top: 18px;
    margin-bottom: 34px;
  }
  .ac_box {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box:nth-child(3) {
      margin-bottom: 0;
    }
    .box {
      margin-bottom: 70px;
      img {
        width: 70px;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        color: #202020;
      }
    }
  }
}
</style>